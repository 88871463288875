<template>
  <div>
    <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">1+1 驚喜價 ( {{ eventSum }} 件)</h4>
    <table class="table table-borderless mb-0">
      <thead>
        <tr class="border-bottom d-none d-lg-table-row">
          <th colspan="2" scope="col" width="55%" class="ps-md-5 py-md-3">商品資料</th>
          <th scope="col" class="py-md-3 text-center">單價</th>
          <th scope="col" class="py-md-3 text-center" width="20%">數量</th>
          <th scope="col" class="py-md-3 text-center">小計</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(product, idx) in eventCartList" :key="product+'1'">
          <tr class="cartBorderBottom">
            <td class="ps-3 ps-lg-5 pb-0 pt-2 pt-lg-3 pb-lg-3 w-30 w-lg-15">
              <img class="img-fluid" :src="product.ImageUrl" alt="product image">
            </td>
            <td class="py-md-3">
              <router-link :to="`/product/${product.Id}`" class="d-block">
                <p>{{ product.Name }}</p>
                <div class="d-flex align-items-center mt-2" v-if="product.Gift">
                  <p class="fs-7 px-1 bg-primary text-white me-1">贈品</p>
                  <small class="text-muted">{{ product.Gift.Name }}</small>
                </div>
              </router-link>
            </td>
            <td class="py-md-3 d-none d-lg-table-cell text-center px-0 px-xl-2">$ {{ $currency.currency(product.Price) }}</td>
            <td class="py-md-3 d-none d-lg-table-cell">
              <div class="productNumber input-group">
                <button
                  type="button"
                  class="btn border-dark bg-white rounded-0"
                  @click.prevent="minusNum(product, idx)"
                >
                  -
                </button>
                <input
                  type="number"
                  class="form-control border-dark text-center"
                  aria-label="product number"
                  min="1"
                  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                  v-model.number="product.Quantity"
                  @change="numInput(product)"
                />
                <button type="button" class="bg-white btn border-dark rounded-0" @click.prevent="addNum(product)">
                  +
                </button>
              </div>
            </td>
            <td class="py-md-3 d-none d-lg-table-cell text-center">$ {{ $currency.currency(product.Price * product.Quantity) }}</td>
            <td class="py-md-3 text-center">
              <button
                type="button"
                class="btn fs-5"
                @click.prevent="deleteProduct(idx)"
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          <tr class="d-lg-none">
            <td colspan="6" class="text-end fw-light px-3 pt-0 pb-2">$ {{ $currency.currency(product.Price) }}</td>
          </tr>
          <tr class="border-bottom d-lg-none">
            <td colspan="6" class="px-3 pb-3 pt-0">
              <div class="d-flex justify-content-end">
                <div class="productNumber input-group w-45">
                  <button
                    type="button"
                    class="btn bg-white border-dark rounded-0"
                    @click.prevent="minusNum(product)"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    class="form-control border-dark text-center py-1"
                    aria-label="product number"
                    min="1"
                    onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                    v-model.number="product.Quantity"
                    @change="numInput(product)"
                  />
                  <button type="button" class="btn bg-white border-dark rounded-0" @click.prevent="addNum(product)">
                    +
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <tr v-if="eventCartList.length > 0">
          <td colspan="6" class="px-3 px-lg-5 pb-0 pt-3 pb-lg-3">
            <AddonProductList :addon-list="addonList" :product-num="productNum" @get-addon="getAddon"></AddonProductList>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddonProductList from '@/components/AddonProductList.vue'

export default {
  components: {
    AddonProductList
  },
  data () {
    return {
      eventCartList: '',
      addonList: '',
      productNum: 0,
      newAddonList: [] //* 自子元件傳出的加購品
    }
  },
  props: {
    eventList: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['updateAllCart', 'checkEventCart'],
  methods: {
    //* 商品數量減少
    minusNum (pd, idx) {
      pd.Quantity--
      if (pd.Quantity < 1) {
        pd.Quantity = 1
        this.deleteProduct(idx)
      }
      this.checkAddon()
    },
    //* 商品數量增加
    addNum (pd) {
      pd.Quantity++
      if (pd.Quantity > pd.Stock) {
        pd.Quantity = pd.Stock
      }
      this.checkAddon()
    },
    //* 商品 input 輸入，數量不可輸入空值、0、非正整數
    numInput (pd) {
      const rule = /^[0-9]*[1-9][0-9]*$/
      if (pd.Quantity === '') {
        pd.Quantity = 1
      } else if (pd.Quantity > pd.Stock) {
        pd.Quantity = pd.Stock
      } else if (pd.Quantity === 0) {
        pd.Quantity = 1
      } else if (!rule.test(pd.Quantity)) {
        pd.Quantity = 1
      }
      this.checkAddon()
    },
    //* 商品移出購物車
    deleteProduct (idx) {
      this.$swal.fire({
        title: '刪除產品',
        text: '您確定要刪除?',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#4D4D4D',
        confirmButtonText: '確定刪除',
        cancelButtonText: '取消',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.eventCartList.splice(idx, 1)
          this.checkAddon()
          // * A 區商品皆被移除，回傳 [] 給父元件
          if (this.eventCartList.length === 0) {
            this.newAddonList = []
            this.eventCartList = []
            this.$emit('checkEventCart', [])
            this.$emit('updateAllCart', [])
          }
        }
      })
    },
    getAddon (products) {
      this.newAddonList = products // * 自子元件傳出的修改後加購品列表
      // * 計算加購品總數
      const sumNum = this.newAddonList.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      // * 檢查 A + B 數量
      const eventCart = [...this.eventCartList, ...this.newAddonList]
      this.$emit('checkEventCart', eventCart)
      if (sumNum === this.productNum) {
        this.$emit('updateAllCart', eventCart)
      }
    },
    checkAddon () {
      // * 計算加購品總數
      const sumAddon = this.newAddonList.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      const sumEvent = this.eventCartList.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )

      // * 檢查 A + B 數量
      const eventCart = [...this.eventCartList, ...this.newAddonList]
      this.$emit('checkEventCart', eventCart)
      if (sumAddon === sumEvent) {
        this.$emit('updateAllCart', eventCart)
      }
    }
  },
  computed: {
    eventSum () {
      const eventCart = [...this.eventCartList, ...this.newAddonList]
      const sumNum = eventCart.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      return sumNum
    }
  },
  watch: {
    eventList: {
      handler: function () {
        this.eventCartList = this.eventList.filter((item) => item.Type === 'A')
        this.addonList = this.eventList.filter((item) => item.Type === 'B')
      },
      deep: true,
      immediate: true
    },
    // * 計算 A 區商品總數量給 addonproductlist 比對加購商品數量
    eventCartList: {
      handler: function (n) {
        this.productNum = n.reduce(
          (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
        )

        // * 檢查 A + B 數量
        const eventCart = [...this.eventCartList, ...this.newAddonList]
        this.$emit('checkEventCart', eventCart)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cartSpace{
  background-color: #F0F0F2;
}
.cartBorderBottom{
  border: none;
  @media (min-width: 992px) {
    border: 1px solid #e9ecef;
  }
}
</style>
